<template>
    <div>
        <vx-card v-if="route === 0">
            <div class="vx-row mb-10">
                <!-- titulo -->
                <div class="vx-col w-1/2">
                    <h2 class="text-info">{{ this.$t('Nuevos Productos') }}</h2>
                    <p style="color: #7c92b3;">{{ this.$t('Selecciona un método para agregar tus productos') }}</p>
                </div>

                <!-- boton descarga -->
                <div class="vx-col w-1/2">
                    <button @click="downloadFileFormat()" class="vs-component vs-button rounded-full vs-button-info vs-button-filled small float-right">
                        <img :src="require(`@/assets/icons/DownloadFileIcon.svg`)" width="20px" class="inline-block align-middle mr-2"/>
                        {{ this.$t('Descargar formato de Carga Masiva') }}
                    </button>
                </div>

                <!-- caja uno a uno -->
                <div class="vx-col w-1/2 mt-10">
                    <h3 class="text-gray">{{ this.$t('Crear un Producto') }}</h3>

                    <div class="w-full border-2 border-dashed mt-2 rounded-lg text-center pt-5 pb-5 pl-32 pr-32 cursor-pointer height" @click="goToCreateOneByOne()">
                        <img :src="require(`@/assets/icons/PlusCircleGrayIcon.svg`)" width="70px" class="inline-block align-middle"/><br/><br/>
                        <h4>{{ this.$t('Crear producto 1 a 1') }}</h4><br/>
                        <span>{{ this.$t('Agrega todos tus productos uno por uno con nuestro formulario de cargue de productos') }}</span><br/><br/>
                        <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full">{{ this.$t('Empezar') }}</vs-button>
                    </div>
                </div>

                <!-- caja masiva -->
                <div class="vx-col w-1/2 mt-10">
                    <h3 class="text-gray">{{ this.$t('Carga Masiva de Productos') }}</h3>

                    <div class="w-full border-2 border-dashed mt-2 rounded-lg text-center pt-5 pb-5 pl-32 pr-32 cursor-pointer height" @click="goToMassive()">
                        <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="80px" class="inline-block align-middle"/><br/><br/>
                        <h4>{{ this.$t('Cargar todos tus productos') }}</h4><br/>
                        <span>{{ this.$t('Agrega todos tus productos con nuestro formato de carga masiva') }}</span><br/><br/>
                        <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full">{{ this.$t('Empezar') }}</vs-button>
                    </div>
                </div>

                <!-- caja uno a uno lead -->
                <!-- <div class="vx-col lg:w-full sm:w-full mt-10">
                    <h3 class="text-gray">Crear un producto lead</h3>

                    <div class="w-full border-2 border-dashed mt-2 rounded-lg text-center pt-5 pb-5 pl-32 pr-32 cursor-pointer height" @click="goToLeadOne()">
                        <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="50px" class="inline-block align-middle"/><br/><br/>
                        <h4>Crea tu producto lead</h4><br/>
                        <span>Agrega todos tus productos lead uno a uno con el formulario</span><br/><br/>
                        <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full">Empezar</vs-button>
                    </div>
                </div> -->
            </div>
        </vx-card>

        <one-by-one v-else-if="route === 1"></one-by-one>

        <massive-load v-else-if="route === 2"></massive-load>

        <new-product-lead v-else-if="route === 3"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import oneByOne from './NewProductOneByOne.vue';
import massiveLoad from './MassiveLoadProducts.vue';
import NewProductLead from './NewProductLead.vue';

export default Vue.extend({
    data() {
        return {
            route: 0,
        }
    },

    methods: {
        goToCreateOneByOne() {
            this.route = 1;
        },

        goToMassive() {
            this.route = 2;
        },

        goToLeadOne() {
            this.route = 3;
        },

        downloadFileFormat() {
            this.$vs.loading();
            this.$store.dispatch( 'getFileUrl', '/documents/example-formats/products.xlsx' )
            .then( url => {
                this.$vs.loading.close();
                if( url ) {
                    const link = document.createElement('a');
                    
                    link.setAttribute( 'download', 'true' );
                    link.href = url;
                    link.click();
                    link.remove();
                }
            });
        },

    },

    components: {
        oneByOne,
        massiveLoad,
        NewProductLead,
    },

})
</script>

<style lang="scss">
.height {
    height: 90%;
}
</style>
