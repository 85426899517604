<template>
    <vx-card>
        <h2>Crear Producto Lead 1 a 1</h2>
        <span class="text-light">Ingresa los datos de producto</span>

        <div class="vx-row mt-10">
            <!-- categoria -->
            <div class="vx-col w-1/2">
                <label for="category">Categoría del producto</label>
                
                <v-select
                    :options="categories"
                    v-model="product.category_id"
                    label="title"
                    :reduce="category => category.id"
                    id="category"
                >
                    <template slot="option" slot-scope="category">
                        {{ category.title }}
                    </template>

                    <template slot="selected-option" slot-scope="category">
                        {{ category.title }}
                    </template>
                </v-select>
                <p class="text-danger text-sm" v-if="!!validate.userTypeError">{{ validate.userTypeError }}</p>
            </div>

            <div class="vx-col w-1/2"></div>

            <!-- nombre -->
            <div class="vx-col w-1/2 mt-5">
                <label for="title">Nombre del producto</label>

                <vs-input
                    v-model="product.title" 
                    :danger="!!validate.nameError"
                    val-icon-danger="clear"
                    class="w-full"
                    id="title"
                />

                <p class="text-danger text-sm" v-if="!!validate.nameError">{{ validate.nameError }}</p>
            </div>

            <!-- sku -->
            <div class="vx-col w-1/2 mt-5">
                <label for="sku">SKU del producto</label>

                <vs-input
                    v-model="product.sku" 
                    :danger="!!validate.skuError"
                    val-icon-danger="clear"
                    @keyup="validateSKU()"
                    class="w-full"
                    id="sku"
                />

                <p class="text-danger text-sm" v-if="!!validate.skuError">{{ validate.skuError }}</p>
            </div>

            <!-- descripción breve -->
            <div class="vx-col w-full mt-5">
                <label for="summary">Descripción Breve</label>

                <vs-textarea
                    v-model="product.summary" 
                    :danger="!!validate.descriptionError"
                    val-icon-danger="clear"
                    @keyup="validate.validateDescription(product.summary)"
                    class="w-full"
                    id="summary"
                />

                <p class="text-danger text-sm" v-if="!!validate.descriptionError">{{ validate.descriptionError }}</p>
            </div>

            <!-- descripción larga -->
            <div class="vx-col w-full mt-5">
                <label for="content">Descripción Larga</label>
                
                <vs-textarea
                    v-model="product.content"
                    class="w-full"
                    id="content"
                />
            </div>

            <!-- emails -->
            <div class="vx-col w-full mt-5">
                <label for="emails">Correos destino</label>
                <p><sub>Separados por comas</sub></p>

                <v-select no-drop taggable multiple :select-on-key-codes="[188, 13]" @search="fetchOptions" @input="setOption" v-model="product.email_list_arr" />
                <p class="text-danger text-sm">{{ validate.lastnameError }}</p>
            </div>

            <!-- imagen -->
            <div class="vx-col w-full mt-10">
                <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" ref="file" accept="image/png, image/jpeg" @change="onChange()" />

                <h3>Foto del producto</h3>
                <span>Recuerda las características mínimas de la imagen de tu producto</span>

                <div
                    v-if="!image.file"
                    class="border-2 border-dashed text-center pt-5 pb-8 mt-5 cursor-pointer"
                    @click="clickFileButton()"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                >
                    <img :src="require(`@/assets/icons/UploadFileIcon.svg`)" width="32px" class="inline-block align-middle"/><br/><br/>
                    <span class="text-gray">Suelta tu imagen aquí, ó</span>&nbsp;
                    <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full small">Busca Archivo</vs-button>
                </div>

                <div v-if="!image.file" class="vx-row mt-2">
                    <div class="vx-col w-1/2">
                        <span class="text-gray">Formatos Soportados: JPG, PNG</span>
                    </div>

                    <div class="vx-col w-1/2">
                        <span class="text-gray float-right">Tamaño Máximo: 5Mb</span>
                    </div>
                </div>

                <vx-card v-else class="mt-2">
                    <div class="vx-row">
                        <div class="vx-col w-auto">
                            <img :src="require(`@/assets/icons/JpgFileIcon.svg`)" width="60px" class="inline-block align-middle"/><br/><br/>
                        </div>

                        <div class="vx-col w-11/12">
                            <feather-icon icon='XIcon' class="float-right cursor-pointer" @click="image.file = null" />
                            <h4>{{ image.file.name }}</h4>
                            <span>Subido {{ image.progress }}%</span>
                            <vs-progress :height="12" :percent="image.progress" :color="image.error ? 'danger' : 'success'"></vs-progress>
                        </div>
                    </div>
                </vx-card>
            </div>

            <!-- boton guardar y agregar -->
            <div class="vx-col w-1/2 mt-10 text-center">
                <vs-button class="rounded-full ml-32" @click="attempSaveProduct( true )">
                    Guardar nuevo producto
                </vs-button>
            </div>

            <!-- boton guardar -->
            <div class="vx-col w-1/2 mt-10 text-center">
                <vs-button type="border" class="rounded-full mr-32" @click="attempSaveProduct()">
                    Guardar y agrega nuevo producto
                </vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script lang="ts">
import Vue from 'vue';
import vSelect from 'vue-select';

import { Validation } from '../../../plugins/validation.js';

export default Vue.extend({

    data() {
        return {
            validate: new Validation(),
            image: {
                progress: 0,
                error: '',
                file: null,
                sku: '',
            },
            options : [],
            show: false,
            validFormats: ['jpg', 'jpeg', 'png'],
        }
    },

    watch : {
        'product.category_id'(val) { this.validate.validateUserType( val ); },
        'product.title'(val) { this.validate.validateName( val ); },
        'product.email_list'( val ) {
            if( !val ) this.validate.lastnameError = "Ingrese al menos una dirección de correo electrónico.";
            else this.validate.lastnameError = "";
        },
    },
    
    created() {
        // set product lead
        this.product.type_product = 2;
    },

    methods : {
        validateSKU() {
            return this.$store.dispatch( 'products/getProductBySKU', this.product.sku )
                .then( res => {                    
                    if( res ) {
                        this.validate.skuError = 'El SKU ya existe';
                        return false;

                    } else {
                        this.validate.skuError = '';
                        return this.validate.validateSKU(this.product.sku);

                    }
                    
                });
        },
        clickFileButton() {
            this.$refs.file.click();
        },

        async onChange() {
            const filename = this.$refs.file.files[0].name;
            const filesize = this.$refs.file.files[0].size;
            const filesizeMb = Math.floor( filesize/1000000 );
            const ext = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
            const isValidSKU = await this.validateSKU();

            const isValidFormat = this.validFormats.filter(format => format === ext);
            
            if( filesizeMb > 2 ) {
                this.$vs.notify({
                    title: `La imagen tiene un tamaño de ${filesizeMb} Mb`,
                    text: `El límite de tamaño es de 2Mb`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7000,
                });
                return;
            }

            if( isValidFormat.length === 0 ) {                
                this.$vs.notify({
                    title: 'Formato Incorrecto',
                    text: 'Cargue archivos .jpg ó .png solamente',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
                return;
            }

            if( !isValidSKU ) {
                this.$vs.notify({
                    title: 'Ingrese un código SKU válido',
                    text: '',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
                return;
            }
            
            this.image.file = this.$refs.file.files[0];
            this.image.sku = this.product.sku;
            // this.$store.dispatch( 'product/loadProductImage', this.image );
        },

        dragover( ev ) {            
            ev.preventDefault();
            
            if (!ev.currentTarget.classList.contains('bg-primary')) {
                ev.currentTarget.classList.remove('bg-gray-100');
                ev.currentTarget.classList.add('bg-primary');
            }
        },

        dragleave( ev ) {
            ev.currentTarget.classList.remove('bg-primary');
        },

        drop( ev ) {
            ev.preventDefault();

            this.$refs.file.files = ev.dataTransfer.files;
            this.onChange();
            ev.currentTarget.classList.remove('bg-primary');
        },

        fetchOptions() {              
            // const isValidEmail = this.validate.validateEmail( ev.slice(0,-1) );
            // if( !isValidEmail ) {
                
            // }
                
        },

        setOption( ev ) {            
            const last = ev[ev.length - 1];

            if( last === undefined ) {
                this.product.email_list = "";
                this.product.email_list_arr = [];
                return;
            }

            const isValidEmail = this.validate.validateEmail( last );
            
            if( isValidEmail ) this.product.email_list = ev.toString();
            else this.product.email_list_arr = this.product.email_list.split(',');
        },

        async attempSaveProduct( go=false ) {
            // validar campos
            const isValidCategorie = this.validate.validateUserType( this.product.category_id );
            const isValidTitle     = this.validate.validateName( this.product.title );
            const isValidSku       = await this.validateSKU( this.product.sku );
            const isValidEmails    = this.validate.validateLastname( this.product.email_list );

            if( !isValidCategorie || !isValidTitle || !isValidSku || !isValidEmails ) {
                this.$vs.notify({
                    title: 'Complete los campos',
                    text: '',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                })
                return;
            }
            // cargar imagen a firebase si es cargada
            if( this.image.file ) {
                this.$store.dispatch( 'products/uploadProductImageToFirebase', this.image )
                    .then( link => {
                        // setear enlace de imagen subida al objeto productos
                        this.product.image_url = link;
                        this.saveProduct( go ); // Metdodo para cargar producto con o sin imagen;
                    });

            } else {
                this.saveProduct( go ); // Metdodo para cargar producto con o sin imagen;

            }

        },

        saveProduct( go=false ) {
            this.$vs.loading();
            this.$store.dispatch( 'products/saveProduct', this.product )
                .then( res => {
                    this.$vs.loading.close();

                    if( res && go ) {
                        this.$router.push({path: '/app/products/leads'});

                    } else if ( res && !go ){
                        this.image = {
                            progress: 0,
                            error: '',
                            file: null,
                            sku: '',
                        }
                    }
                });
        },
    },

    computed :  {
        categories() { return this.$store.state.categories.categories; },
        product : {
            get() {
                const store = this.$store;
                const product = store.state.products.product;
                let productProxy = new Proxy( product, {
                    set (obj, key, value) {
                        store.commit('products/SET_PRODUCT', {
                            ...obj,
                            [key]: value,
                        });    
                        return true
                    }
                });
    
                return productProxy;
            },
            set( val ) {
                this.$store.commit('products/SET_PRODUCT', val);
            }
        },
    },

    components : {
        vSelect,
    },
})
</script>

<style lang="scss">

#vs14__listbox {
    display: none !important;
}

</style>
