<template>
  <vx-card>
    <h2>{{ this.$t('Crear Producto 1 a 1') }}</h2>
    <span class="text-light">{{ this.$t('Ingresa los datos de producto') }}</span>

    <div class="vx-row mt-10">
      <!-- categoria -->
      <div class="vx-col w-1/2">
        <label for="category">{{ this.$t('Categoría del producto') }}</label>
          
        <v-select
          :options="categories"
          v-model="product.category_id"
          label="title"
          :reduce="category => category.id"
          id="Category"
        >
            <template slot="option" slot-scope="category">
                {{ category.title }}
            </template>

            <template slot="selected-option" slot-scope="category">
                {{ category.title }}
            </template>
        </v-select>
        <p class="text-danger text-sm">{{ errors.category_id }}</p>
      </div>
      <!-- Formúla Médica -->
      <!-- <div class="vx-col w-1/2">
        <label for="medicalFormula">¿Necesita Fórmula Médica?</label>
          
        <v-select
          :options="medicalFormula"
          v-model="product.med_form"
          label="title"
          :reduce="medicalFormula => medicalFormula.value"
          id="medicalFormula"
        >
          <template slot="option" slot-scope="medicalFormula">
              {{ medicalFormula.title }}
          </template>

          <template slot="selected-option" slot-scope="medicalFormula">
              {{ medicalFormula.title }}
          </template>
        </v-select>
      </div> -->
      <!-- sku -->
      <div class="vx-col w-1/2 mt-0">
        <label for="sku">{{ this.$t('SKU del producto') }}</label>

        <vs-input
          v-model="product.sku" 
          :danger="!!validate.skuError"
          val-icon-danger="clear"
          @keyup="validateSKU()"
          class="w-full"
          id="sku"
        />

        <p class="text-danger text-sm" v-if="!!validate.skuError">{{ validate.skuError }}</p>
      </div>
      <!-- nombre -->
      <div class="vx-col w-1/2 mt-5">
        <label for="title">{{ this.$t('Nombre del producto') }}</label>

        <vs-input
          v-model="product.title" 
          :danger="!!validate.nameError"
          val-icon-danger="clear"
          @keyup="validate.validateName(product.title)"
          class="w-full"
          id="title"
        />

        <p class="text-danger text-sm" v-if="!!validate.nameError">{{ validate.nameError }}</p>
      </div>
      <!-- descripción breve -->
      <div class="vx-col w-full mt-5">
        <label for="summary">{{ this.$t('Descripción Breve') }}</label>

        <vs-textarea
          v-model="product.summary" 
          :danger="!!validate.descriptionError"
          val-icon-danger="clear"
          @keyup="validate.validateDescription(product.summary)"
          class="w-full"
          id="summary"
        />

        <p class="text-danger text-sm" v-if="!!validate.descriptionError">{{ validate.descriptionError }}</p>
      </div>
      <!-- descripción larga -->
      <div class="vx-col w-full mt-5">
        <label for="content">{{ this.$t('Descripción Larga') }}</label>
        
        <vs-textarea
          v-model="product.content"
          class="w-full"
          id="content"
        />
      </div>
      <!-- imagen -->
      <div class="vx-col w-full mt-5">
        <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" ref="file" accept="image/png, image/jpeg" @change="onChange()" />

        <h3>{{ this.$t('Foto del producto') }}</h3>
        <span>{{ this.$t('Recuerda las características mínimas de la imagen de tu producto') }}</span>

        <div
          v-if="!image.file"
          class="border-2 border-dashed text-center pt-5 pb-8 mt-5 cursor-pointer"
          @click="clickFileButton()"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <img :src="require(`@/assets/icons/UploadFileIcon.svg`)" width="32px" class="inline-block align-middle"/><br/><br/>
          <span class="text-gray">{{ this.$t('Suelta tu imagen aquí, ó') }}</span>&nbsp;
          <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full small">{{ this.$t('Busca Archivo') }}</vs-button>
        </div>

        <div v-if="!image.file" class="vx-row mt-2">
          <div class="vx-col w-1/2">
            <span class="text-gray">{{ this.$t('Formatos Soportados: JPG, PNG') }}</span>
          </div>

          <div class="vx-col w-1/2">
            <span class="text-gray float-right">{{ this.$t('Tamaño Máximo:') }} 5Mb</span>
          </div>
        </div>

        <vx-card v-else class="mt-2">
          <div class="vx-row">
            <div class="vx-col w-auto">
              <img :src="require(`@/assets/icons/JpgFileIcon.svg`)" width="60px" class="inline-block align-middle"/><br/><br/>
            </div>

            <div class="vx-col w-11/12">
              <feather-icon icon='XIcon' class="float-right cursor-pointer" @click="image.file = null" />
              <h4>{{ image.file.name }}</h4>
              <span>{{ this.$t('Subido') }} {{ image.progress }}%</span>
              <vs-progress :height="12" :percent="image.progress" :color="image.error ? 'danger' : 'success'"></vs-progress>
            </div>
          </div>
        </vx-card>
      </div>
      <!-- boton guardar y agregar -->
      <div class="vx-col w-1/2 mt-10 text-center">
        <vs-button class="rounded-full ml-32" @click="attempSaveProduct( true )">
          {{ this.$t('Guardar nuevo producto') }}
        </vs-button>
      </div>
      <!-- boton guardar -->
      <div class="vx-col w-1/2 mt-10 text-center">
        <vs-button type="border" class="rounded-full mr-32" @click="attempSaveProduct()">
          {{ this.$t('Guardar y agrega nuevo producto') }}
        </vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script lang="ts">
import Vue from 'vue';
import vSelect from 'vue-select';

import { Validation } from '../../../plugins/validation.js';

export default Vue.extend({

  data() {
    return {
      medicalFormula: [
          { value: 1, title: 'sí' },
          { value: 0, title: 'no' },
      ],
      validate: new Validation(),
      image: {
          progress: 0,
          error: '',
          file: null,
          sku: '',
      },
      validFormats: ['jpg', 'jpeg', 'png'],
      errors : {
        category_id: '',
      }
    }
  },

  watch: {
    'product.category_id'(val) {
      if( !val ) this.errors.category_id = this.$t('Selecciona una categoría');
      else this.errors.category_id = '';
    },
  },

  created() {
    this.$store.dispatch( 'categories/getAllCategories' );
  },
    
  methods: {
    async validateSKU() {
      const res = await this.$store.dispatch( 'products/getProductBySKU', this.product.sku );
      if( res ) {
        this.validate.skuError = this.$t('El SKU ya existe');
        return false;

      } else {
        this.validate.skuError = '';
        return this.validate.validateSKU(this.product.sku);

      }
    },

    clickFileButton() {
      this.$refs.file.click();
    },

    async onChange() {
      const filename = this.$refs.file.files[0].name;
      const filesize = this.$refs.file.files[0].size;
      const filesizeMb = Math.floor( filesize/1000000 );
      const ext = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
      const isValidSKU = await this.validateSKU();
      const isValidFormat = this.validFormats.filter(format => format === ext);
        
      if( filesizeMb > 2 ) {
        this.$vs.notify({
          title: `La imagen tiene un tamaño de ${filesizeMb} Mb`,
          text: this.$t(`El límite de tamaño es de 2Mb`),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 7000,
        });
        return;
      }

      if( isValidFormat.length === 0 ) {                
        this.$vs.notify({
          title: this.$t('Formato Incorrecto'),
          text: this.$t('Cargue archivos .jpg ó .png solamente'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });
        return;
      }

      if( !isValidSKU ) {
        this.$vs.notify({
          title: this.$t('Ingrese un código SKU válido'),
          text: '',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });
        return;
      }
        
      this.image.file = this.$refs.file.files[0];
      this.image.sku = this.product.sku;
      // this.$store.dispatch( 'product/loadProductImage', this.image );
    },

    dragover( ev ) {            
      ev.preventDefault();
      
      if (!ev.currentTarget.classList.contains('bg-primary')) {
        ev.currentTarget.classList.remove('bg-gray-100');
        ev.currentTarget.classList.add('bg-primary');
      }
    },

    dragleave( ev ) {
      ev.currentTarget.classList.remove('bg-primary');
    },

    drop( ev ) {
      ev.preventDefault();

      this.$refs.file.files = ev.dataTransfer.files;
      this.onChange();
      ev.currentTarget.classList.remove('bg-primary');
    },

    async validateProductData() {
      // validate category
      if( !this.product.category_id ) this.errors.category_id = this.$t('Selecciona una categoría');
      else this.errors.category_id = '';

      const isValidCategorie = this.validate.validateUserType( this.product.categorieId ); // validar categoria
      const isValidSKU = await this.validateSKU(); // validar codigo SKU
      const isValidName = this.validate.validateName( this.product.title ); // validar nombre
      const isValidDesc = this.validate.validateDescription( this.product.summary ); // validar descripcion
      // retornar toast alerta de campos
      if( !isValidCategorie || !isValidSKU || !isValidName || !isValidDesc || !this.product.category_id ) {
        this.$vs.notify({
          title: this.$t('Alerta'),
          text: this.$t('Valide los campos antes de continuar'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });

        return false
      }
      
      return true
    },

    async attempSaveProduct( go=false ) {
      // validar campos
      const isValidData = await this.validateProductData();
      if( !isValidData ) {return;}
      // cargar imagen a firebase si es cargada
      if( this.image.file ) {
        this.$store.dispatch( 'products/uploadProductImageToFirebase', this.image )
          .then( link => {
            // setear enlace de imagen subida al objeto productos
            this.product.image_url = link;
            this.saveProduct( go ); // Metdodo para cargar producto con o sin imagen;
        });

      } else {
        this.saveProduct( go ); // Metdodo para cargar producto con o sin imagen;

      }
    },

    async saveProduct( go=false ) {
      this.$vs.loading();
      const res = await this.$store.dispatch( 'products/saveProduct', this.product );
      this.$vs.loading.close();

      if( res && go ) {
        this.$router.push({path: '/app/products'});

      } else if ( res && !go ){
        this.product = {
          categorieId: 0,
          sku: '',
          name: '',
          description: '',
          image_url: '',
        }

        this.image = {
          progress: 0,
          error: '',
          file: null,
          sku: '',
        }
      }
    },

  },

  computed: {
    categories() { return this.$store.state.categories.categories; },
    localProduct() { return this.$store.state.products.product; },
    product() {
      const store = this.$store;
      const product = store.state.products.product;
      let productProxy = new Proxy( product, {
        set (obj, key, value) {
          store.commit('products/SET_PRODUCT', {
            ...obj,
            [key]: value,
          });    
          return true
        }
      });

      return productProxy;
    },
  },

  components: {
      vSelect,
  },

});

</script>
