<template>
    <vx-card>
        <div class="vx-row">
            <!-- titulo -->
            <div class="vx-col w-1/2">
                <h2>{{ this.$t('Carga Masiva de Productos') }}</h2>
                <span>{{ this.$t('Sube tu archivo con los datos completos') }}</span>
            </div>
            <!-- boton descarga -->
            <div class="vx-col w-1/2">
                <button @click="downloadFileFormat()" class="vs-component vs-button rounded-full vs-button-info vs-button-filled small float-right">
                    <img :src="require(`@/assets/icons/DownloadFileIcon.svg`)" width="20px" class="inline-block align-middle mr-2"/>
                    {{ this.$t('Descargar formato de Carga Masiva') }}
                </button>
            </div>
            <!-- carga de archivos -->
            <div class="vx-col lg:w-1/2 sm:w-full mt-10">
                <!-- carga de productos -->
                <h4>{{ this.$t('Lista de Productos') }}</h4>

                <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" ref="fileXLSX" accept=".xlsx , .csv" @change="onChangeXLSX()" />

                <div
                    class="border-2 border-dashed text-center pt-5 pb-8 mt-5 cursor-pointer rounded-lg"
                    @click="clickFileButtonXLSX()"
                    @dragover="dragoverXLSX"
                    @dragleave="dragleaveXLSX"
                    @drop="dropXLSX"
                >
                    <img :src="require(`@/assets/icons/UploadFileIcon.svg`)" width="32px" class="inline-block align-middle"/><br/><br/>
                    <span class="text-gray">{{ this.$t('Suelta tu archivo aquí, ó') }}</span>&nbsp;
                    <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full small">{{ this.$t('Busca Archivo') }}</vs-button>
                </div>

                <p class="text-gray mt-2">{{ this.$t('Formatos Soportados: .xlsx, .csv') }}</p>

                <!-- carga de imagenes -->
                <h4 class="mt-10">{{ this.$t('Fotografía de Productos') }}</h4>

                <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" ref="fileZIP" accept=".zip" @change="onChangeZIP()" />

                <div
                    class="border-2 border-dashed text-center pt-5 pb-8 mt-5 cursor-pointer rounded-lg"
                    @click="clickFileButtonZIP()"
                    @dragover="dragoverZIP"
                    @dragleave="dragleaveZIP"
                    @drop="dropZIP"
                >
                    <img :src="require(`@/assets/icons/UploadFileIcon.svg`)" width="32px" class="inline-block align-middle"/><br/><br/>
                    <span class="text-gray">{{ this.$t('Suelta tu archivo aquí, ó') }}</span>&nbsp;
                    <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full small">{{ this.$t('Busca Archivo') }}</vs-button>
                </div>

                <div class="vx-row mt-2">
                    <div class="vx-col w-1/2 border">
                        <span class="text-gray">{{ this.$t('Formatos Soportados:') }} .zip</span>
                    </div>

                    <div class="vx-col w-1/2">
                        <span class="text-gray float-right">{{ this.$t('Tamaño Máximo:') }} {{size_zip_limtit}} MB</span>
                    </div>
                </div>
            </div>

            <!-- lectura de archivos -->
            <div class="vx-col lg:w-1/2 sm:w-full mt-10">

                <!-- lista de archivos -->
                <VuePerfectScrollbar style="height: 85%;">
                    <h4>{{ this.$t('Archivos subidos') }}</h4>

                    <vx-card v-if="files.length === 0" class="mt-5 text-center">
                        <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="50px" class="inline-block align-middle mr-5" />
                        {{ this.$t('Carga un archivo') }}
                    </vx-card>

                    <vx-card v-else class="mt-5" v-for="(file, i) in files" v-bind:key="i">
                        <div class="vx-row">
                            <div class="vx-col w-auto">
                                <div v-if="getFileFormat(file.file.name) === 'xlsx'">
                                    <img :src="require(`@/assets/icons/ExcelIcon.svg`)" width="50px" />
                                </div>

                                <div v-else-if="getFileFormat(file.file.name) === 'zip'">
                                    <img :src="require(`@/assets/icons/ZipFileIcon.svg`)" width="50px" />
                                </div>

                                <div v-else-if="getFileFormat(file.file.name) === 'jpeg'">
                                    <img :src="require(`@/assets/icons/JpgFileIcon.svg`)" width="50px" />
                                </div>

                                <div v-else style="width: 50px;">
                                    <feather-icon icon="FileTextIcon"/>
                                </div>
                            </div>

                            <div class="vx-col w-5/6">
                                <vs-button color="transparent" text-color="#000" size="small" class="float-right" @click="deleteFile(i)">
                                    <feather-icon icon="XIcon" />
                                </vs-button>

                                <h3>{{ file.file.name }}</h3>
                                <span>{{ this.$t('Subido') }} {{ file.progress }}%</span>
                                <vs-progress :height="12" :percent="file.progress" :color="file.loadError ? 'danger' : 'success'"></vs-progress>
                            </div>
                        </div>
                    </vx-card>
                </VuePerfectScrollbar>

                <!-- boton guardar -->
                <div class="vx-col w-full mt-5">
                    <button class="vs-component vs-button rounded-full vs-button-primary vs-button-filled float-right" :disabled="files.length === 0" @click="uploadFiles()">
                        {{ this.$t('Guardar') }}
                    </button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script lang="ts">
import Vue from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default Vue.extend({

    data() {  
        return {
            files: [],
            size_zip_limtit : 30, // Megabytes
        }
    },

    watch: {
        files: {
            handler( val ) {
                const uploaded = val.filter( f => f.progress === 100 );
                if( uploaded.length === this.files.length ) this.$router.push( { path : '/app/products' } );
            },
            deep: true,
        }
    },
    
    methods: {
        getFileFormat( filename ) {
            return filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
        },

        onChangeXLSX() {
            const filename = this.$refs.fileXLSX.files[0].name;
            const ext = this.getFileFormat(filename);

            if( ext !== 'xlsx' && ext !== 'csv' ) {
                return this.$vs.notify({
                    title: this.$t('Formato Incorrecto'),
                    text: this.$t('Cargue archivos .xlsx ó .csv solamente'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
            }

            const fileMapped = [this.$refs.fileXLSX.files[0]].map( file => {
                let ob = Object.assign({}, file);
                ob.file = file;
                ob.progress = 0;
                ob.loadError = '';
                return ob;
            });

            this.files.push(fileMapped[0]);
            this.$refs.fileXLSX.value = '';
        },

        clickFileButtonXLSX() {
            this.$refs.fileXLSX.click();
        },

        dragoverXLSX( ev ) {            
            ev.preventDefault();
            
            if (!ev.currentTarget.classList.contains('bg-primary')) {
                ev.currentTarget.classList.remove('bg-gray-100');
                ev.currentTarget.classList.add('bg-primary');
            }
        },

        dragleaveXLSX( ev ) {
            ev.currentTarget.classList.remove('bg-primary');
        },

        dropXLSX( ev ) {
            ev.preventDefault();
            this.$refs.fileXLSX.files = ev.dataTransfer.files;
            this.onChangeXLSX();
            ev.currentTarget.classList.remove('bg-primary');
        },

        onChangeZIP() {
            const file = this.$refs.fileZIP.files[0];
            const size_mb = parseFloat( (file.size / (1024 * 1024)).toFixed(2) );
            const filename = file.name;
            const ext = this.getFileFormat(filename);

            if( ext !== 'zip' ) {                
                this.$vs.notify({
                    title: this.$t('Formato Incorrecto'),
                    text: this.$t('Cargue archivos .zip solamente'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7000,
                });
                return;
            }

            if( size_mb > this.size_zip_limtit ) {                
                this.$vs.notify({
                    title: this.$t('El archivo supera el tamaño máximo'),
                    text: `Seu arquivo tem um tamanho de ${ size_mb } Mb`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7000,
                });
                return;
            }

            const fileMapped = [this.$refs.fileZIP.files[0]].map( file => {
                let ob = Object.assign({}, file);
                ob.file = file;
                ob.progress = 0;
                ob.loadError = '';
                return ob;
            });

            this.files.push(fileMapped[0]);
            this.$refs.fileZIP.value = '';
        },

        clickFileButtonZIP() {
            this.$refs.fileZIP.click();
        },

        dragoverZIP( ev ) {            
            ev.preventDefault();
            
            if (!ev.currentTarget.classList.contains('bg-primary')) {
                ev.currentTarget.classList.remove('bg-gray-100');
                ev.currentTarget.classList.add('bg-primary');
            }
        },

        dragleaveZIP( ev ) {
            ev.currentTarget.classList.remove('bg-primary');
        },

        dropZIP( ev ) {
            ev.preventDefault();
            this.$refs.fileZIP.files = ev.dataTransfer.files;
            this.onChangeZIP();
            ev.currentTarget.classList.remove('bg-primary');
        },

        deleteFile( index ) {
            this.files.splice( index, 1 );
        },

        uploadFiles() {
            this.$store.dispatch( 'products/attempUploadFiles', this.files );
            this.$vs.notify({
                title: this.$t('Los productos se cargarán asíncronicamente'),
                text: this.$t('Abre las notificaciones para verificar su estado'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'primary',
                time: 7000
            });
        },

        downloadFileFormat() {
            this.$vs.loading();
            this.$store.dispatch( 'getFileUrl', '/documents/example-formats/products.xlsx' )
            .then( url => {
                this.$vs.loading.close();
                if( url ) {
                    const link = document.createElement('a');
                    
                    link.setAttribute( 'download', 'true' );
                    link.href = url;
                    link.click();
                    link.remove();
                }
            });
        },
    },

    components: {
        VuePerfectScrollbar,
    }

})
</script>